import React from 'react'
import PropTypes from 'prop-types'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { Facebook, Twitter, LinkedIn, WhatsApp, Messenger } from './Social-icons'

import { SocialShareWrapper, Separator, IconWrapper } from './styles'

const SocialShare = ({ url, title }) => {
  const social = {
    twitterHandle: '@rentlio',
    config: {
      url: `${encodeURIComponent(url)}`,
      title: title
    }
  }
  const mixpanel = useMixpanel()

  const MessengerShareButton = ({ children, url, appId }) => {
    const handleClick = () => {
      window.open(
        'fb-messenger://share?link=' + encodeURIComponent(url) + '&app_id=' + encodeURIComponent(appId),
        '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'
      )
    }
    return (
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        {children}
      </div>
    )
  }

  const LinkedinShareButton = ({ children, url, appId }) => {
    const handleClick = (e) => {
      handleTrackedRentlioWebappClick(e, url, '', mixpanel, 'blog_post_shared', 'linkedin', true, true)
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=rentl.io&source=Rentlio`,
        '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'
      )
    }
    return (
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        {children}
      </div>
    )
  }

  return (
    <SocialShareWrapper>
      <FacebookShareButton url={url} onClick={(e) => handleTrackedRentlioWebappClick(e, url, '', mixpanel, 'blog_post_shared', 'facebook', true, true)}>
        <IconWrapper>
          <Facebook className="facebook" />
        </IconWrapper>
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} via={social.twitterHandle.split('@').join('')} onClick={(e) => handleTrackedRentlioWebappClick(e, url, '', mixpanel, 'blog_post_shared', 'twitter', true, true)}>
        <IconWrapper>
          <Twitter className="twitter" />
        </IconWrapper>
      </TwitterShareButton>
      <LinkedinShareButton url={url} title={title} source={url}>
        <IconWrapper>
          <LinkedIn className="linkedin" />
        </IconWrapper>
      </LinkedinShareButton>
      <Separator />
      <WhatsappShareButton url={url} title={social.config.title} onClick={(e) => handleTrackedRentlioWebappClick(e, url, '', mixpanel, 'blog_post_shared', 'whatsapp', true, true)}>
        <IconWrapper>
          <WhatsApp className="whats-up" />
        </IconWrapper>
      </WhatsappShareButton>
      {/* <MessengerShareButton url="https://rentl.io/" title={social.config.title} appId="123456789">
        <IconWrapper>
          <Messenger className="messenger" />
        </IconWrapper>
      </MessengerShareButton> */}
    </SocialShareWrapper>
  )
}

export default SocialShare
