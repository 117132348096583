import React from 'react'

const Facebook = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.57303 3.33131H12V0.000183494H8.98876H8.35955C8.35955 0.000183494 6.06742 -0.0520694 4.61423 1.60696C4.61423 1.60696 3.62547 2.42995 3.61049 4.82052V7.3156H0V10.8557H3.59551V20H7.74532V10.8557H11.3258L11.8202 7.3156H7.7603V4.82052C7.7603 4.53313 7.91011 3.30518 9.57303 3.33131Z"
        fill="#0D3151"
      />
    </svg>
  )
}

Facebook.defaultProps = {
  width: 12,
  height: 20
}

export default Facebook
