import styled from 'styled-components'

export const BlogPostContentSection = styled.section`
  padding: 1rem 0 5rem 0;
  position: relative;

  @media only screen and (max-width: 1100px) {
    padding: 10rem 0 5rem 0;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
`

export const StickyWrapper = styled.div`
  display: block;

  @media only screen and (max-width: 1100px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const BlogPostContentArticle = styled.article`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;

  h1 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: ${({ theme }) => theme.blogPostHeadings.h1};
    font-weight: 800;
    line-height: 5.14rem;
    max-width: 780px;
    margin: 4rem auto;
  }

  h2 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: ${({ theme }) => theme.blogPostHeadings.h2};
    font-weight: 800;
    line-height: 4.28rem;
    max-width: 780px;
    margin: 4rem auto;
  }

  h3 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: ${({ theme }) => theme.blogPostHeadings.h3};
    font-weight: 800;
    line-height: 3.57rem;
    max-width: 780px;
    margin: 4rem auto;
  }

  h4 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: ${({ theme }) => theme.blogPostHeadings.h4};
    font-weight: 800;
    line-height: 2.85rem;
    max-width: 780px;
    margin: 4rem auto;
  }

  h5 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: ${({ theme }) => theme.blogPostHeadings.h5};
    font-weight: 800;
    line-height: 2.14rem;
    max-width: 780px;
    margin: 4rem auto;
  }

  h6 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: ${({ theme }) => theme.blogPostHeadings.h6};
    font-weight: 800;
    line-height: 1.71rem;
    max-width: 780px;
    margin: 4rem auto;
  }

  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.28rem;
    line-height: 2rem;
    max-width: 780px;
    margin: 2rem auto;
  }

  a {
    color: ${({ theme }) => theme.colors.vividBlue};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .gatsby-image-wrapper {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
  }

  figcaption {
    text-align: right;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Roboto';
    font-size: 0.85rem;
    line-height: 2.28rem;
    opacity: 0.6;
    padding: 1rem 0;

    &::after {
      content: ' ';
      display: inline-flex;
      vertical-align: middle;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.vividBlue};
      width: 15px;
      margin-left: 1rem;
    }
  }

  blockquote {
    position: relative;
    margin: 5rem 0;
    padding: 1rem 0 1rem 3rem;

    > p {
      color: ${({ theme }) => theme.colors.veryDarkBlue};
      font-family: 'Barlow';
      font-size: 2.85rem;
      font-weight: 600;
      line-height: 3.5rem;
      margin: 0 0 4rem;
      width: 100%;
    }

    cite {
      color: ${({ theme }) => theme.colors.darkGrey};
      font-size: 1.14rem;
      font-style: normal;
    }

    &::before {
      content: ' ';
      position: absolute;
      left: 0;
      display: block;
      height: 100%;
      width: 4px;
      background: linear-gradient(
        180deg,
        #a9cf3a 2.7%,
        #a7cf40 7.72%,
        #a2ce52 15.26%,
        #99cd6f 24.41%,
        #8bcb92 34.79%,
        #78cabb 46.16%,
        #59c9e7 57.51%,
        #1d9ed9 100%
      );
      transform: translateY(-10%);
    }
  }

  strong {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-weight: 500;
  }

  ul {
    list-style: disc;
    width: 780px;
    margin: 5rem auto;
    padding-left: 3rem;

    li {
      color: ${({ theme }) => theme.colors.darkGrey};
      font-size: 1.42rem;
      line-height: 2.28rem;
      padding-left: 2.5rem;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }

  .wp-block-columns {
    display: flex;
    justify-content: space-between;
  }

  .wp-block-column {
    display: flex;
    flex-direction: column;
    flex-basis: calc(50% - 16px);
  }

  .published-date {
    font-size: 1rem;
  }

  .twitter-tweet {
    margin: 2rem auto;
  }

  .instagram-media {
    margin: 2rem auto !important;

    &::before {
      content: none;
    }
  }

  .wp-block-image {
    &.large {
      width: 100%;
      max-width: 780px;
      margin: 4rem auto;
    }

    &.medium {
      width: 100%;
      max-width: 600px;
      margin: 4rem auto;
    }

    &.small {
      width: 100%;
      max-width: 300px;
      margin: 4rem auto;
    }
  }

  .aligncenter {
    text-align: center;
  }

  @media only screen and (max-width: 1024px) {
    ul {
      width: 100%;

      li {
        padding-left: 2rem;
      }
    }

    .wp-block-columns {
      flex-direction: column;
    }

    .wp-block-column {
      margin: 1rem 0;
    }
  }

  @media only screen and (max-width: 768px) {
    blockquote > p {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0 0 3rem;
    }

    figcaption {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      line-height: 2rem;

      &::after {
        margin: 1rem 0 0 0;
      }
    }
  }
`
