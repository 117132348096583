import React from 'react'

const LinkedIn = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.263529 5.30821H3.57647V15.9875H0.263529V5.30821ZM1.92 0C2.97412 0 3.84 0.867931 3.84 1.92454C3.84 2.98115 2.97412 3.84908 1.92 3.84908C0.853333 3.84908 0 2.99373 0 1.92454C0 0.867931 0.853333 0 1.92 0Z"
        fill="#0D3151"
      />
      <path
        d="M5.66016 5.3081H8.83506V6.76721H8.88525C9.32447 5.92445 10.4037 5.04395 12.0225 5.04395C15.3731 5.04395 16.0005 7.25778 16.0005 10.1383V15.9999H12.6751V10.8049C12.6751 9.57224 12.65 7.97476 10.9558 7.97476C9.23663 7.97476 8.9731 9.32067 8.9731 10.7169V15.9999H5.66016V5.3081Z"
        fill="#0D3151"
      />
    </svg>
  )
}

LinkedIn.defaultProps = {
  width: 16,
  height: 16
}

export default LinkedIn
