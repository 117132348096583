import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  BlogPostIntroWrapper,
  BlogPostIntroHero,
  Title,
  AuthorBox,
  Name,
  Category,
  TimeToRead,
  ImageContainer
} from './styles'

import Container from '../../UI/Container'
import { timeToRead } from '../../../helper'

const BlogPostIntro = ({ locale, title, content, categories, author, authorImage, image, location }) => {
  let path = locale === 'en' ? `/${locale}/category/` : `/kategorija/`

  const parentCategory =
    categories.length > 1
      ? categories.find((c) => {
          return c.slug === 'price-korisnika' || c.slug === 'customer-success'
        })
      : categories[0]

  return (
    <BlogPostIntroWrapper>
      <Container>
        <BlogPostIntroHero>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <AuthorBox>
            <figure>
              <Img fluid={authorImage} alt="" />
            </figure>
            <Name>{author ? author : 'No Author'}</Name>
            <Link to={`${path}${parentCategory.slug}${location.search}`}>
              <Category>{parentCategory.name}</Category>
            </Link>
            <TimeToRead>
              <span>{`${timeToRead(content)} read`}</span>
            </TimeToRead>
          </AuthorBox>
        </BlogPostIntroHero>
      </Container>
      <ImageContainer>
        <Img fluid={image} />
      </ImageContainer>
    </BlogPostIntroWrapper>
  )
}

BlogPostIntro.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogPostIntro
