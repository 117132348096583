import React from 'react'
import PropTypes from 'prop-types'

import { ListWrapper } from './styles'

const List = ({ items, onSelected, itemsCountNumber, onMouseOver }) => {
  return (
    <ListWrapper className="scale-up-center">
      {items.length !== 0 ? (
        items.map((item, key) => {
          return (
            <li
              //   onMouseOver={onMouseOver}
              key={`${item}-${key}`}
              onClick={() => onSelected(item)}
              //   data-item={key}
              //   className={itemsCountNumber === key ? hover : null}
            >
              {item ? item : null}
            </li>
          )
        })
      ) : (
        <li>No more items</li>
      )}
    </ListWrapper>
  )
}

export default List
