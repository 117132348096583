import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import parse from 'html-react-parser'

import '~/../@wordpress/block-library/build-style/style.css'

import { BlogPostContentSection, BlogPostContentArticle, StickyWrapper, FlexWrapper } from './styles'
import Container from '../../UI/Container'
import SocialShare from '../../UI/Social-share/'

const BlogPostContent = ({ content, title, url }) => {
  const {
    allWordpressWpMedia: { edges: media }
  } = useStaticQuery(graphql`
    query {
      allWordpressWpMedia {
        edges {
          node {
            id
            source_url
            alt_text
            localFile {
              extension
              publicURL
              url
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const srcSplitter = (src) => `/${src.split('/').slice(3).join('/')}`
  const ContentParsed = parse(content, {
    replace: function (domNode) {
      if (domNode.name === 'img') {
        // Check if protocol exists
        const splitURL = domNode.attribs.src.split('/')

        let imageSrc = domNode.attribs.src
        // let isProtocol = false

        if (splitURL[0] === 'http:' || splitURL[0] === 'https:') {
          // isProtocol = true
          imageSrc = srcSplitter(imageSrc)
        }

        const image = media.filter((mediaItem) => {
          // Will leave this code here just in case if something again goes wrong, hope not...
          // if (isProtocol) return srcSplitter(mediaItem.node.localFile.url) === imageSrc

          return mediaItem.node.source_url === imageSrc
        })

        if (image.length > 0) {
          if (image[0].node.localFile.extension === 'gif') {
            return (
              <img
                key={image[0].node.id}
                src={image[0].node.localFile.publicURL}
                className={domNode.attribs.class}
                alt={image[0].node.alt_text}
              />
            )
          }

          if (image[0].node.localFile.childImageSharp !== null) {
            return (
              <Img
                key={image[0].node.id}
                fluid={image[0].node.localFile.childImageSharp.fluid}
                alt={image[0].node.alt_text}
              />
            )
          }

          return (
            <img
              key={image[0].node.id}
              src={image[0].node.localFile.publicURL}
              className={domNode.attribs.class}
              alt={image[0].node.alt_text}
            />
          )
        }
      }
    }
  })
  return (
    <BlogPostContentSection>
      <Container>
        <FlexWrapper>
          <StickyWrapper>
            <SocialShare url={url.href} title={title} />
          </StickyWrapper>
          <BlogPostContentArticle>{ContentParsed}</BlogPostContentArticle>
        </FlexWrapper>
      </Container>
    </BlogPostContentSection>
  )
}

export default BlogPostContent
