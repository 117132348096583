import styled from 'styled-components'

export const SocialShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 15%;
  background: ${({ theme }) => theme.colors.pureWhite};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 2.85rem 1.71rem 2rem 1.71rem;

  button:focus {
    outline: none;
  }

  @media only screen and (max-width: 1100px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 2rem 1rem 2rem;
  }
`

export const Separator = styled.span`
  width: 20px;
  border: 1px solid #eaeaea;
  margin: 1.4rem 0;

  @media only screen and (max-width: 1100px) {
    transform: rotate(90deg);
  }
`

export const IconWrapper = styled.div`
  margin: 0.7rem 0;

  svg {
    transition: opacity 0.2s ease-in-out;
  }

  .facebook:hover,
  .twitter:hover,
  .linkedin:hover,
  .whats-up:hover,
  .messenger:hover {
    opacity: 0.6;
  }

  @media only screen and (max-width: 1100px) {
    margin: 0 1rem;
  }
`
