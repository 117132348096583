import styled from 'styled-components'

import clockIcon from '../../../images/clock.svg'

export const BlogPostIntroWrapper = styled.div`
  padding: 5rem 0;
`

export const BlogPostIntroHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 7rem 0 5rem 0;
`

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 4.28rem;
  font-weight: 800;
  line-height: 5.14rem;
  margin-bottom: 4.78rem;
  width: 80%;
  text-align: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 2rem;
    line-height: 2.7rem;
    text-align: left;
  }
`

export const AuthorBox = styled.div`
  display: flex;
  align-items: center;

  figure {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
    mask-image: radial-gradient(white, black);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const Name = styled.span`
  color: ${(props) => props.theme.colors.darkGrey};

  &::after {
    content: '-';
    display: inline-flex;
    margin: 0 0.5rem;
  }
`
export const Category = styled.span`
  color: ${(props) => props.theme.colors.vividBlue};
`

export const TimeToRead = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.darkGrey};

  span {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.darkGrey};

    &::before {
      content: '';
      display: inline-flex;
      width: 16px;
      height: 16px;
      background-image: url(${clockIcon});
      background-repeat: no-repeat;
      margin: 0 0.5rem 0 1rem;
    }
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  height: 600px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 10px;

  @media only screen and (max-width: 1100px) {
    border-radius: 0;
    height: auto;
  }
`
