import styled from 'styled-components'

export const BlogPostNewsletterSection = styled.section`
  padding: 5rem 0;
  position: relative;

  @media screen and (max-width: 425px) {
    padding-top: 1rem;
  }
`
export const BlogPostNewsletterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 3rem 0;
  padding: 4rem 0;
  position: relative;
  background: radial-gradient(91.53% 537.19% at 34.46% 108.61%, #0d3151 0%, #134776 100%);
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 8px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  align-items: center;
  justify-content: center;
`

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;

  @media only screen and (max-width: 1024px) {
    align-items: center;
  }
`

export const Title = styled.h3`
  font-family: 'Barlow';
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 2.14rem;
  font-weight: 800;
  line-height: 2.85rem;
  margin-bottom: 1rem;
  max-width: 580px;

  @media only screen and (max-width: 1024px) {
    text-align: center;
    padding: 1rem;
  }

  @media screen and (max-width: 425px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.7rem;
    line-height: 2rem;
  }
`

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.lightestGrey};
  line-height: 1.7rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1024px) {
    text-align: center;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 425px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`

export const ImageContainer = styled.figure`
  width: 300px;
  height: 100%;

  @media only screen and (max-width: 1024px) {
    width: 200px;
  }
`
