import React, { useContext } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import {
  BlogSuggestionSection,
  BlogSuggestionWrapper,
  Title,
  SuggestedArticles,
  Article,
  ArticleImage,
  ArticleTitle,
  TimeToRead
} from './styles'

import Container from '../../UI/Container'
import { LanguageContext } from '../../Layout'

import { timeToRead } from '../../../helper'

const BlogSuggestion = ({ posts, imgPlaceholder, location }) => {
  if (posts) posts = posts.slice(1, 4)
  const { i18n } = useContext(LanguageContext)

  return (
    <BlogSuggestionSection>
      <Container>
        <BlogSuggestionWrapper>
          <Title>{i18n.similarArticles}</Title>
          <SuggestedArticles>
            {posts.map(({ node }) => {
              const {
                id,
                title,
                content,
                slug,
                lang,
                acf: { author_name },
                featured_media
              } = node

              const featuredImage = featured_media !== null && featured_media.localFile.childImageSharp.fluid
              const locale = lang === 'en' ? `/${lang}/` : '/'

              return (
                <Article key={id}>
                  <Link to={`${locale}blog/${slug}${location.search}`}>
                    <ArticleImage>
                      <Img fluid={featuredImage || imgPlaceholder} alt="" />
                    </ArticleImage>
                    <div>
                      <span>{author_name ? author_name : 'No Author'}</span>
                      <TimeToRead>
                        <span>{`${timeToRead(content)} read`}</span>
                      </TimeToRead>
                    </div>
                    <ArticleTitle className="article-title" dangerouslySetInnerHTML={{ __html: title }} />
                  </Link>
                </Article>
              )
            })}
          </SuggestedArticles>
        </BlogSuggestionWrapper>
      </Container>
    </BlogSuggestionSection>
  )
}

BlogSuggestion.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogSuggestion
