import styled from 'styled-components'

export const BlogAuthorSection = styled.section`
  padding: 5rem 0;

  @media screen and (max-width: 425px) {
    padding: 1rem 0;
  }
`
export const BlogAuthorWrapper = styled.div`
  max-width: 780px;
  margin: 0 auto;
`
export const AuthorBox = styled.div`
  display: flex;
  align-items: center;

  figure {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
    overflow: hidden;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  max-width: 650px;
  font-size: 1.14rem;
  line-height: 2.28rem;
  margin-left: 3.57rem;

  @media only screen and (max-width: 1024px) {
    text-align: center;
    margin: 2rem 0;
  }
`
