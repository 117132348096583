import React from 'react'

const WhatsApp = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.90979C0 3.99692 4.03737 0 8.99998 0C13.9766 0 18.014 3.99692 18 8.92367C18 13.8365 13.9766 17.8335 8.99998 17.8335C7.41587 17.8335 5.92989 17.4171 4.64018 16.7093L0.126168 18L1.26168 13.4618C0.462616 12.1295 0 10.5752 0 8.90979ZM4.87849 15.0439C6.0841 15.8628 7.54204 16.3485 9.11213 16.3485C13.2336 16.3485 16.5841 13.0316 16.5841 8.93755C16.5841 4.85736 13.2476 1.54048 9.11213 1.54048C4.97663 1.54048 1.62616 4.85736 1.64018 8.95143C1.64018 10.5058 2.13084 11.963 2.97196 13.1565L2.31308 15.7795L4.87849 15.0439ZM6.88132 8.03538C6.82875 8.09437 6.77618 8.15335 6.72711 8.2158C7.38257 10.0352 9.68758 11.0031 10.0667 11.1623C10.1011 11.1768 10.1196 11.1846 10.1196 11.1857L10.1197 11.1857C10.3299 10.9775 10.5402 10.7694 10.7505 10.5473C10.7672 10.5293 10.7841 10.5107 10.8012 10.4919C10.942 10.3373 11.0987 10.1651 11.3112 10.1032C11.593 10.0305 11.864 10.1696 12.115 10.2985C12.1512 10.3171 12.1871 10.3355 12.2224 10.353C12.428 10.4548 12.6087 10.5566 12.7894 10.6584C12.8798 10.7092 12.9701 10.7601 13.0635 10.811C13.2738 10.9359 13.4701 11.1024 13.4841 11.3661C13.4981 11.6298 13.428 11.9768 13.3159 12.2266C12.8112 13.3646 10.9047 13.1009 10.9047 13.1009C5.60562 12.1294 4.55422 7.38311 4.55422 7.38311C4.00749 5.70386 5.77384 4.8573 5.77384 4.8573C6.08225 4.70464 6.46076 4.76015 6.68506 4.99608C6.82989 5.16553 6.97472 5.54311 7.08471 5.82984C7.09183 5.8484 7.0988 5.86657 7.10562 5.88428C7.14066 5.96755 7.1722 6.04735 7.20375 6.12715C7.23529 6.20695 7.26683 6.28674 7.30188 6.37001C7.40001 6.61982 7.49814 6.89738 7.48412 7.17494C7.47301 7.39486 7.3123 7.5625 7.15538 7.72619C7.11425 7.7691 7.07337 7.81173 7.03552 7.85497C6.98646 7.91742 6.93389 7.9764 6.88132 8.03538Z"
        fill="#0D3151"
      />
    </svg>
  )
}

WhatsApp.defaultProps = {
  width: 18,
  height: 18
}

export default WhatsApp
