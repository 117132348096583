import React from 'react'

const Twitter = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.29085 16C13.8562 16 17.9739 9.84739 17.9739 4.49799C17.9739 4.32129 17.9739 4.14458 17.9575 3.98394C18.7582 3.42169 19.4608 2.6988 20 1.89558C19.2647 2.21687 18.4641 2.4257 17.6471 2.53815C18.4967 2.04016 19.1503 1.25301 19.4608 0.305221C18.6601 0.771084 17.7941 1.10843 16.8464 1.28514C16.1111 0.497992 15.049 0 13.8562 0C11.585 0 9.7549 1.81526 9.7549 4.03213C9.7549 4.35341 9.78758 4.65863 9.85294 4.94779C6.45425 4.78715 3.41503 3.18072 1.38889 0.738956C1.02941 1.33333 0.833333 2.0241 0.833333 2.76305C0.833333 4.16064 1.55229 5.39759 2.6634 6.12048C1.99346 6.10442 1.35621 5.91165 0.800654 5.62249C0.800654 5.63855 0.800654 5.65462 0.800654 5.67068C0.800654 7.63052 2.22222 9.25301 4.10131 9.62249C3.75817 9.71888 3.39869 9.76707 3.02288 9.76707C2.76144 9.76707 2.5 9.73494 2.2549 9.68675C2.77778 11.2932 4.29739 12.4659 6.09477 12.498C4.6732 13.5904 2.9085 14.2329 0.980392 14.2329C0.653595 14.2329 0.326797 14.2169 0 14.1687C1.81373 15.3253 3.97059 16 6.29085 16Z"
        fill="#0D3151"
      />
    </svg>
  )
}

Twitter.defaultProps = {
  width: 20,
  height: 16
}

export default Twitter
