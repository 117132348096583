import styled from 'styled-components'
import { Link } from 'gatsby'

import arrowRightGreen from '../../../images/arrow-right-green.svg'
import clockIcon from '../../../images/clock.svg'

export const BlogSuggestionSection = styled.section`
  padding: 5rem 0;
`
export const BlogSuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7.14rem;

  p {
    display: flex;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-size: 1.14rem;
    flex-basis: 50%;
  }
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: bold;
  line-height: 3.28rem;
  margin: 10rem 0;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 70px);
    background: linear-gradient(
      90deg,
      #a9cf3a 0%,
      #a7cf40 5.15%,
      #a2ce52 12.91%,
      #99cd6f 22.31%,
      #8bcb92 32.97%,
      #78cabb 44.65%,
      #59c9e7 56.32%,
      #1d9ed9 99.98%
    );
  }

  @media only screen and (max-width: 768px) {
    margin: 3rem 0 10rem 0;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.14rem;
  line-height: 2rem;

  &::before {
    content: '';
    display: inline-flex;
    width: 18px;
    height: 12px;
    background-image: url(${arrowRightGreen});
    background-repeat: no-repeat;
    margin-right: 1rem;
  }
`

export const SuggestedArticles = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    > div:nth-child(n + 2) {
      display: none;
    }
  }

  @media only screen and (max-width: 1165px) {
    justify-content: space-evenly;
    > div:nth-child(n + 3) {
      display: none;
    }
  }
`

export const Article = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;

  a > div {
    display: flex;
  }

  span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    margin: 1rem 0;
  }

  &:hover {
    .article-title {
      color: ${({ theme }) => theme.colors.vividBlue};
    }
  }

  @media only screen and (max-width: 425px) {
    width: 300px;
  }
`
export const ArticleImage = styled.figure`
  width: 340px;
  height: 230px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  img {
    height: 100%;
  }

  @media only screen and (max-width: 425px) {
    width: 300px;
    height: auto;
  }
`
export const ArticleTitle = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 600;
  line-height: 1.85rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
`

export const TimeToRead = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    opacity: 0.6;

    &::before {
      content: '';
      display: inline-flex;
      width: 16px;
      height: 16px;
      background-image: url(${clockIcon});
      background-repeat: no-repeat;
      margin: 0 0.5rem 0 1rem;
    }
  }
`
