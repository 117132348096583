import React from 'react'
import Img from 'gatsby-image'

import { BlogAuthorSection, BlogAuthorWrapper, Description, AuthorBox } from './styles'

import Container from '../../UI/Container'

const BlogAuthor = ({ author, authorImage, employees }) => {
  const filteredEmployee = employees.filter(({ node: { title } }) => title.toLowerCase() === author.toLowerCase())

  let description
  if (filteredEmployee && filteredEmployee.length > 0) {
    const {
      node: {
        acf: { employee_description }
      }
    } = filteredEmployee[0]

    description = employee_description
  }

  return (
    <BlogAuthorSection>
      <Container>
        <BlogAuthorWrapper>
          <AuthorBox>
            <figure>
              <Img fluid={authorImage} />
            </figure>
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              <Description>No description</Description>
            )}
          </AuthorBox>
        </BlogAuthorWrapper>
      </Container>
    </BlogAuthorSection>
  )
}

export default BlogAuthor
