import React from 'react'
import PropTypes from 'prop-types'

const ArrowIcon = ({ className, width, height, color }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.13333 0H12L6.13333 6L0 0H6.13333Z" fill={color} />
    </svg>
  )
}

ArrowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
}

ArrowIcon.defaultProps = {
  width: 12,
  height: 6,
  color: '#F7F7F7'
}
export default ArrowIcon
