import React from 'react'

const Messenger = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.03108 0 0 3.72752 0 8.33788C0 10.9537 1.31627 13.2916 3.35649 14.8283V18L6.41682 16.3161C7.23949 16.545 8.09506 16.6594 9 16.6594C13.9689 16.6594 18 12.9319 18 8.32153C18 3.72752 13.9689 0 9 0ZM9.88848 11.2153L7.60146 8.77929L3.12614 11.2153L8.0457 6L10.3985 8.43597L14.808 6L9.88848 11.2153Z"
        fill="#0D3151"
      />
    </svg>
  )
}

Messenger.defaultProps = {
  width: 18,
  height: 18
}

export default Messenger
