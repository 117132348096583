import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Disqus from 'disqus-react'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import BlogPostIntro from 'components/Blog-post-screen/Blog-post-intro'
import BlogPostContent from 'components/Blog-post-screen/Blog-post-content'
import BlogPostNewsletter from 'components/Blog-post-screen/Blog-post-newsletter'
import BlogSuggestion from 'components/Blog-post-screen/Blog-suggestion'
import BlogAuthor from 'components/Blog-post-screen/Blog-author'
import Container from 'components/UI/Container'
import CallToAction from 'components/CTA'

const MainContent = styled.main` 
  background-color: #ffffff;
`

const BlogPost = ({ data, pageContext, location }) => {
  const {
    site,
    file: {
      childImageSharp: { fluid: placeholder }
    },
    post: {
      id,
      title,
      content,
      excerpt,
      yoast_meta: {
        yoast_wpseo_title,
        yoast_wpseo_metadesc,
        yoast_wpseo_facebook_title,
        yoast_wpseo_facebook_description,
        yoast_wpseo_facebook_type,
        yoast_wpseo_twitter_title,
        yoast_wpseo_twitter_description
      },
      categories,
      acf: {
        author_name,
        author_image: {
          localFile: {
            childImageSharp: { fluid: authorImage }
          }
        },
        keywords
      },
      featured_media
    },
    posts: { edges: posts },
    employees: { edges: employees },
    ctaData: { edges: ctaData }
  } = data

  //Filter CTA data
  const {
    node: {
      acf: { cta_home }
    }
  } = ctaData.filter(({ node: { acf } }) => acf.cta_home !== null)[0]

  // Disqus
  const disqusShortname = 'rentlio'
  const disqusConfig = {
    url: `${site.siteMetadata.siteUrl}${location.pathname}`,
    identifier: id,
    title: title
  }

  const featuredImage = featured_media !== null ? featured_media?.localFile?.childImageSharp : null

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt.replace(/<\/?p[^>]*>/g, ''),
    lang: pageContext.locale,
    image: featuredImage ? featuredImage.resize : null,
    keywords: keywords === null ? [] : keywords.split(/[\s,]+/),
    pathname: location.pathname,
    facebook: { yoast_wpseo_facebook_title, yoast_wpseo_facebook_description, yoast_wpseo_facebook_type },
    twitter: { yoast_wpseo_twitter_title, yoast_wpseo_twitter_description }
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        image={seo.image}
        facebook={seo.facebook}
        twitter={seo.twitter}
        keywords={seo.keywords}
        article
        location={location}
      />
      <Header
        mode="dark"
        hasBlog
        isBlogPost
        locale={pageContext.locale}
        posts={posts}
        opacity={0.3}
        location={location}
      />
      <BlogPostIntro
        locale={pageContext.locale}
        title={title}
        content={content}
        categories={categories}
        author={author_name}
        authorImage={authorImage}
        image={featuredImage ? featuredImage.fluid : placeholder}
        location={location}
      />
      <MainContent>
        <BlogPostContent content={content} title={title} url={location} />
        {/* <BlogPostNewsletter locale={pageContext.locale} /> */}
        <CallToAction data={cta_home} locale={pageContext.locale} location={location} />
        <BlogAuthor authorImage={authorImage} author={author_name} employees={employees} />
        <Container>
          <section style={{ margin: '1rem auto' }}>
            <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </section>
        </Container>
        <BlogSuggestion posts={posts} imgPlaceholder={placeholder} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($id: Int, $locale: String!) {
    site {
      id
      siteMetadata {
        siteUrl
      }
    }
    file(relativePath: { eq: "featured-placeholder.jpg" }) {
      childImageSharp {
        fluid(quality: 85, maxWidth: 1300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    post: wordpressPost(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      id
      title
      content
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
      }

      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 85, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }

      acf {
        author_name
        author_image {
          localFile {
            childImageSharp {
              fluid(quality: 85, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        keywords
      }

      categories {
        id
        name
        slug
      }
    }

    posts: allWordpressPost(filter: { lang: { eq: $locale }, wordpress_id: { ne: $id } }) {
      edges {
        node {
          id
          title
          content
          excerpt
          slug
          lang
          acf {
            author_name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 85, maxWidth: 340, maxHeight: 230) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    employees: allWordpressWpEmployees(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          slug
          title
          acf {
            employee_description
          }
        }
      }
    }
    ctaData: allWordpressPage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          acf {
            cta_home {
              title
              description
            }
          }
        }
      }
    }
  }
`
