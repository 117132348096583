import React, { useState, useRef, useEffect, useCallback } from 'react'

import { SelectWrapper, Selector, Label } from './styles'
import ArrowIcon from './arrowIcon'
import List from './List'

const Select = ({ label, i18n, onSelectedIndustry, className }) => {
  const [show, setShow] = useState(false)
  const [item, setItem] = useState('')
  const wrapperRef = useRef(null)

  const handleShowList = useCallback(() => {
    setShow(!show)
  }, [])

  const handleOutsideClick = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setShow(false)
    }
  }

  //TODO: dodati keycodove
  const handleKeyEvents = (e) => {
    if (e.keyCode === 8) {
      e.preventDefault()
      setItem('')
    }
  }

  const onItemSelected = (item) => {
    setItem(item)
    onSelectedIndustry(item)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    if (item !== '') {
      setShow(false)
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [item])

  return (
    <SelectWrapper className={className}>
      <Selector onClick={handleShowList} ref={wrapperRef} onKeyDown={handleKeyEvents}>
        {item === '' ? <span>{i18n.yourIndustry}</span> : <span>{item}</span>}
        <ArrowIcon className={show ? 'rotate' : ''} color={'#7A7A7A'} />
        <input type="hidden" value={item} />
        {show && (
          <List
            show={show}
            items={['Hotel', 'Hostel', `${i18n.vacationRentals}`, `${i18n.propertyManagers}`, `${i18n.other}`]}
            onSelected={onItemSelected}
          />
        )}
      </Selector>
      {/* <Label className={item ? 'shrink' : ''}>{label}</Label> */}
    </SelectWrapper>
  )
}

export default Select
