import styled from 'styled-components'

export const FormWrapper = styled.form`
  display: flex;

  input {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1rem;
    font-weight: 300;
    line-height: 2rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    width: 100%auto;
    max-width: 240px;
    height: 50px;
    padding: 1rem;
    margin-bottom: 1.42rem;
    margin-right: 1.5rem;

    &::placeholder {
      color: ${({ theme }) => theme.colors.darkGrey};
    }
    &:focus {
      outline: none;
    }
  }

  .industry-select {
    flex-basis: 30%;
    margin-right: 1.5rem;
  }

  @media only screen and (max-width: 1100px) and (min-width: 1025px) {
    justify-content: center;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;

    select {
      height: 62px;
    }

    input,
    select {
      margin: 0 0 2rem 0;
    }
  }

  @media only screen and (max-width: 425px) {
    input {
      width: 240px;
    }
    .industry-select {
      width: 240px;
      margin-right: 0;
    }
  }
`
export const SubscribeButton = styled.button`
  display: block;
  height: 50px;
  width: auto;
  background: ${({ theme }) => theme.colors.vividBlue};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.pureWhite};
  font-size: 1.28rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 1rem 2rem;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);

  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.5);
    background-color: hsla(199, 76%, 43%, 1);
    transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.3);
  }

  @media screen and (max-width: 1024px) {
    margin-top: 2rem;
  }
`
export const ConformationMsg = styled.div`
  display: flex;
  height: 84px;
  margin-bottom: 2rem;
  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const SendingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;

  span {
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin: 0 0.3rem;
    background-color: #fff;
    border-radius: 50%;
    animation: bounce 0.4s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes bounce {
    20% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`
export const ErrorMsg = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  height: 22px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
