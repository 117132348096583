import styled, { css } from 'styled-components'

export const SelectWrapper = styled.div`
  position: relative;
  flex-basis: 100%;
`

const animateLabel = css`
  top: 0px;
  font-size: 12px;
  color: ${({ grey, lightGrey, theme }) =>
    grey ? theme.colors.veryDarkBlue : lightGrey ? theme.colors.lightGrey : theme.colors.lightestGrey};
`

export const Selector = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  color: ${({ grey, theme }) => (grey ? theme.colors.lightGrey : theme.colors.lightestGrey)};
  font-size: 1rem;
  line-height: 2rem;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;

  &:focus {
    outline: none;
    color: ${({ grey, theme }) => (grey ? theme.colors.veryDarkBlue : theme.colors.lightestGrey)};
  }

  &:focus ~ label {
    ${animateLabel}
  }

  svg {
    position: absolute;
    right: 0;
    transition: transform 0.2s ease-in-out;
    margin-right: 1rem;

    &.rotate {
      transform: rotate(180deg);
    }
  }

  span {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-weight: 300;
    margin-left: 0.5rem;
  }
`
export const Label = styled.label`
  color: ${({ lightGrey, theme }) => (lightGrey ? theme.colors.lightGrey : theme.colors.lightestGrey)};
  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 600;
  line-height: 1.71rem;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 30px;
  transition: 300ms ease all;

  &.shrink {
    ${animateLabel}
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`
