import styled from 'styled-components'

export const ListWrapper = styled.ul`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  color: black;
  background-color: white;
  padding: 1rem 0;
  top: 55px;
  left: 0;
  position: absolute;
  z-index: 1;

  animation: scale-up-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  li {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-size: 1.14rem;
    font-weight: 500;
    line-height: 1.71;
    padding: 0.4rem 2rem;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.veryLightGray};
    }
  }

  @keyframes scale-up-center {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 425px) {
    left: 0;
    width: 100%;
  }
`
